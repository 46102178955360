<template>
  <PageStatsDashboard2BoekingenOverzicht
    homepage
    :is-default-open="IS_DEFAULT_OPEN"
    :filters="filters"
    :render-key="today.getTime()"
    :filters-vergelijken="null"
    title-prefix="BAM / TARGET"
    @open="IS_DEFAULT_OPEN = $event"
  />
</template>

<script setup>
import { defineAsyncComponent, ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'

const PageStatsDashboard2BoekingenOverzicht = defineAsyncComponent(() => import('@/pages/stats/Dashboard2/Boekingen/Overzicht.vue'))

const today = new Date()

const filters = ref({
  day: today.getDate(),
  month: today.getMonth() + 1,
  year: today.getFullYear(),
  avg_price: 141.67,
  bedrijf_thema: null,
})

const IS_DEFAULT_OPEN = useLocalStorage('HOME_ADMIN_BAM_TARGETS_IS_DEFAULT_OPEN', false)
</script>
